import Head from 'next/head';
import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useRouter } from 'next/router';
import { removeEmpty } from 'helpers';
import Cookies from 'js-cookie';
import SiteContext from 'context/SiteContext';

const HeadComponent = ({ metadata = {}, keywords, page, canonicalUrl }) => {
    const router = useRouter();

    const {
        store: { settings },
    } = useContext(SiteContext);

    const allowAccessToCurrentPage = useMemo(() => {
        return !(
            !Cookies.get('ZIA_SESS') &&
            router?.asPath?.includes('/account') &&
            !router?.asPath?.includes('/activate')
        );
    }, [router.asPath]);

    const { globalMetadata, globalKeywords, favicon } = { ...settings };

    const siteTitle = 'Zia Tile';
    const defaultTitle = 'Zia Tile | Handmade Cement Tile and Moroccan Zellige - Los Angeles';
    const defaultDescription =
        'The world’s finest handmade encaustic cement tiles and Moroccan zellige. Prices start at $8.50 per square foot and we ship worldwide from Los Angeles.';
    const defaultKeywords = 'Tile, Zellige, Cement Tile, Ceramics, Cotto, Brick, Field tile';

    const getPageMetaData = page => {
        return {
            ...(page?.seo?.title
                ? { title: `${page.seo.title}` }
                : page?.title && { title: `${page.title} | ${siteTitle}` }),
            ...(page?.seo?.description
                ? { description: page.seo.description }
                : page?.description && { description: page.description }),
            ...(page?.image && {
                og_image: page.image,
                twitter_image: page.image,
            }),
        };
    };

    const data = useMemo(() => {
        if (
            !page &&
            router.asPath !== '/' &&
            router.asPath !== '/index' &&
            router.asPath !== '/#' &&
            !router.asPath.includes('/?')
        ) {
            const routerPath = router.asPath.split('?')[0];
            page = {
                title:
                    routerPath.charAt(routerPath.lastIndexOf('/') + 1).toUpperCase() +
                    routerPath.slice(routerPath.lastIndexOf('/') + 2),
            };
        } else if (page) {
            page = removeEmpty(page);
        }
        if (metadata) {
            metadata = removeEmpty(metadata);
        }

        const storyblokMetadata = {
            title: metadata?.title || globalMetadata?.title || defaultTitle,
            description: metadata?.description || globalMetadata?.description || defaultDescription,
            kws: keywords && keywords !== '' ? keywords : globalKeywords || defaultKeywords,
        };
        const pageMetadata = {
            ...getPageMetaData(page),
        };

        const derivedMetadata = {
            ...storyblokMetadata,
            ...pageMetadata,
        };

        const {
            title,
            description,
            kws,

            og_title = title,
            og_description = description,
            og_image = globalMetadata?.og_image,

            twitter_title = title,
            twitter_description = description,
            twitter_image = globalMetadata?.twitter_image,
        } = { ...derivedMetadata };
        return {
            title,
            description,
            kws,
            og_title,
            og_description,
            og_image,
            og_type: router?.asPath?.includes('/blog') ? 'article' : 'website',
            twitter_title,
            twitter_description,
            twitter_image,
        };
    }, [router.asPath, globalMetadata, globalKeywords, metadata, keywords]);

    return (
        <Head>
            <title>
                {allowAccessToCurrentPage ? data.title : globalMetadata?.title || defaultTitle}
            </title>
            <meta
                name="description"
                content={
                    allowAccessToCurrentPage
                        ? data.description
                        : globalMetadata?.description || defaultDescription
                }
            />
            <meta
                name="robots"
                content={`${
                    process.env.NEXT_PUBLIC_ENV === 'STAGING' ? 'noindex,' : 'index, follow,'
                } max-image-preview:large`}
            />
            <meta name="keywords" content={data.kws} />
            {data.og_image && <meta property="og:image" content={data.og_image} />}
            <meta property="og:title" content={data.og_title} />
            <meta property="og:description" content={data.og_description} />
            <meta property="og:url" content={`https://www.ziatile.com${router.asPath}`} />
            <meta property="og:site_name" content="Zia Tile" />
            <meta property="og:type" content={data.og_type} />
            <meta name="twitter:card" content="summary_large_image" />
            {/* Selectively remove rich Pin data from content page(s) */}
            <meta name="pinterest-rich-pin" content="false" />
            {favicon?.filename && favicon?.filename !== '' && (
                <link rel="icon" href={favicon.filename} />
            )}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        </Head>
    );
};

HeadComponent.propTypes = {
    metadata: PropTypes.object,
    keywords: PropTypes.string,
    page: PropTypes.object,
    canonicalUrl: PropTypes.string,
};

export default HeadComponent;
